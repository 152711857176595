<template>
    <div class="mobileBillDetail scrolling">
        <div class="baseInfo">
            <div class="flex title">
                <div class="fwxx">发文信息</div>
            </div>
            <div class="cells">
                <van-cell
                    v-for="(i, index) in baseInfoCell"
                    :key="index"
                    :title="i.title"
                    value-class="value-class"
                    :value="formatValue(i)"
                />
            </div>
            <div class="gapLine"></div>
            <div class="personCell">
                <van-cell v-for="(i, index) in personCell" :key="index" :title="i.title">
                    <div>
                        <a-tag
                            class="tags flex0"
                            v-for="(tag, tagIndex) in formatPerson(billDetailInfo[i.key])"
                            color="blue"
                            :key="tagIndex"
                        >
                            <OpenData
                                :type="tag.type"
                                :openid="tag.userId || tag.departmentId"
                                :corpid="tag.corpId"
                            ></OpenData>
                        </a-tag>
                    </div>
                </van-cell>
            </div>
        </div>
        <div class="fileListInfo">
            <div class="flex title">
                <div class="fwxx">附件内容</div>
            </div>
            <div class="gapLine"></div>
            <div class="personCell">
                <van-cell v-for="(i, index) in fileSeeInfo" :key="index" :title="i.title">
                    <div>
                        <a-tag class="tags flex0" v-for="(tag, tagIndex) in i.val" color="blue" :key="tagIndex">
                            <OpenData type="userName" :openid="tag.userId" :corpid="tag.corpId"></OpenData>
                        </a-tag>
                    </div>
                </van-cell>
            </div>
            <div class="margin-tt">
                <upload :isOnlyShow="true" :fileIds="myData.fileIds" />
            </div>
            <div class="footer" v-if="$route.query.todo === 'accept'">
                <a-button type="primary" @click="acceptBill">接收公文</a-button>
            </div>
        </div>
    </div>
</template>
<script>
import { acceptDocumentBill } from '@/api/modular/fileStream/documentManage'
import upload from '@/views/fileStream/documentManage/upload.vue'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import minxin, { billDetail, initWx } from '../components/minxin'
import { baseInfoCell, personCell } from './config'
export default {
    mixins: [initWx, minxin, billDetail],
    components: {
        OpenData,
        upload,
    },
    computed: {
        fileSeeInfo() {
            const initFileSeeInfo = {
                seeStatusIds: {
                    title: '已预览附件人员：',
                    val: [],
                },
                downloadIds: {
                    title: '已下载附件人员：',
                    val: [],
                },
                acceptIds: {
                    title: '已接受文件人员：',
                    val: [],
                },
            }
            this.billDetailInfo.acceptList.forEach((item) => {
                if (item.acceptStatus === 1) {
                    initFileSeeInfo.acceptIds.val.push(item)
                }
                if (item.downloadStatus === 1) {
                    initFileSeeInfo.downloadIds.val.push(item)
                }
                if (item.seeStatus === 1) {
                    initFileSeeInfo.seeStatusIds.val.push(item)
                }
            })
            return initFileSeeInfo
        },
    },
    data() {
        return {
            baseInfoCell,
            personCell,
        }
    },
    methods: {
        formatValue(i) {
            const val = this.myData[i.key]
            if (!val || val === 'null') return ''
            if (i.renderKey) return this.getValueByKey(this[i.renderKey], val)
            return val
        },
        /**
         * 接收公文
         */
        acceptBill() {
            acceptDocumentBill(this.$route.query.id)
                .then((res) => {
                    if (res.code === 200) {
                        this.$message.success('接收成功')
                        this.$nextTick(() => {
                            this.$router.go(-1)
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        formatPerson(ids) {
            if (!ids) return []
            return JSON.parse(ids)
        },
        getValueByKey(arr, s) {
            // eslint-disable-next-line eqeqeq
            const values = arr.filter((item) => item.code == s)
            if (values.length > 0) {
                return values[0].value
            }
        },
    },
}
</script>
<style lang="less" scoped>
.mobileBillDetail {
    padding: 12px;
    .fwxx {
        font-size: 18px;
        font-weight: bold;
        color: #3e3d3d;
        line-height: 1;
    }
    .fwxx::before {
        content: '';
        width: 4px;
        height: 20px;
        background: #3b9afa;
        display: inline-block;
        position: relative;
        top: 3px;
        margin-right: 10px;
    }
    .gapLine {
        border-top: 1px dashed rgba(59, 154, 250, 0.6);
        margin: 10px 0;
    }
    .value-class {
        white-space: nowrap;
    }
    .margin-tt {
        padding-left: 16px;
        margin-top: 5px;
    }
    .footer {
        padding: 10px 12px 30px;
    }
}
</style>
